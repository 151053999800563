body {
  background-color: #f5f6fa;
}

@-webkit-keyframes animateBubble {
  0% {
    margin-top: 100vh;
    opacity: 1;
  }
  75% {
    opacity: .9;
  }
  100% {
    margin-top: -200px;
    opacity: 0;
  }
}

@-moz-keyframes animateBubble {
  0% {
    margin-top: 100vh;
    opacity: 1;
  }
  75% {
    opacity: .9;
  }
  100% {
    margin-top: -200px;
    opacity: 0;
  }
}

@keyframes animateBubble {
  0% {
    margin-top: 100vh;
    opacity: 1;
  }
  75% {
    opacity: .9;
  }
  100% {
    margin-top: -200px;
    opacity: 0;
  }
}

@-webkit-keyframes sideWays {
  0% {
    margin-left: 0;
    transform: rotate(0);
  }
  100% {
    margin-left: 50px;
    transform: rotate(360deg);
  }
}

@-moz-keyframes sideWays {
  0% {
    margin-left: 0;
    transform: rotate(0);
  }
  100% {
    margin-left: 50px;
    transform: rotate(360deg);
  }
}

@keyframes sideWays {
  0% {
    margin-left: 0;
    transform: rotate(0);
  }
  100% {
    margin-left: 40px;
    transform: rotate(360deg);
  }
}

/* ANIMATIONS */

.x1 {
  -webkit-animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;

  left: 5%;
  top: 5%;

  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
}

.x2 {
  -webkit-animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate;

  left: 5%;
  top: 80%;

  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}

.x3 {
  -webkit-animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate;

  left: 70%;
  top: 40%;

  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transform: scale(0.7);
}

.x4 {
  -webkit-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;

  left: 20%;
  top: 0;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.x5 {
  -webkit-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;

  left: 30%;
  top: 50%;

  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
}

.x6 {
  -webkit-animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate;

  left: 50%;
  top: 0;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
}

.x7 {
  -webkit-animation: animateBubble 20s linear infinite, sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 20s linear infinite, sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 20s linear infinite, sideWays 3s ease-in-out infinite alternate;

  left: 35%;
  top: 60%;
}

.x8 {
  -webkit-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;

  left: 80%;
  top: 10%;

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}

.x9 {
  -webkit-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;

  left: 90%;
  top: 50%;

  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
}

.x10 {
  -webkit-animation: animateBubble 26s linear infinite, sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 26s linear infinite, sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 26s linear infinite, sideWays 2s ease-in-out infinite alternate;

  left: 80%;
  top: 80%;

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}

.x11 {
  -webkit-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;

  left: 70%;
  top: 85%;

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}

.x12 {
  -webkit-animation: animateBubble 18s linear infinite, sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 18s linear infinite, sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 18s linear infinite, sideWays 3s ease-in-out infinite alternate;

  left: 55%;
  top: 85%;

  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  transform: scale(1.5);
}

.x13 {
  -webkit-animation: animateBubble 20s linear infinite, sideWays 5s ease-in-out infinite alternate;
  -moz-animation: animateBubble 20s linear infinite, sideWays 5s ease-in-out infinite alternate;
  animation: animateBubble 20s linear infinite, sideWays 5s ease-in-out infinite alternate;

  left: 65%;
  top: 65%;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
}

.x14 {
  -webkit-animation: animateBubble 26s linear infinite, sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 26s linear infinite, sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 26s linear infinite, sideWays 3s ease-in-out infinite alternate;

  left: 90%;
  top: 45%;

  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
}

.x15 {
  -webkit-animation: animateBubble 17s linear infinite, sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 17s linear infinite, sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 17s linear infinite, sideWays 3s ease-in-out infinite alternate;

  left: 0;
  top: 40%;

  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}

.x16 {
  -webkit-animation: animateBubble 26s linear infinite, sideWays 5s ease-in-out infinite alternate;
  -moz-animation: animateBubble 26s linear infinite, sideWays 5s ease-in-out infinite alternate;
  animation: animateBubble 26s linear infinite, sideWays 5s ease-in-out infinite alternate;

  left: 85%;
  top: 20%;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

/* datepicker */

.DayPicker_weekHeader {
  top: 58px;
}

.CalendarDay__blocked_calendar {
  background: #f5f6fa;
}

select option {
  background-color: green!important;
  font-weight: bold!important;
  color: red!important;
}

/* toastify */

.Toastify__toast-container {
  z-index: 999999999 !important;
  opacity: .8;
}

.Toastify {
  opacity: 1;
  z-index: 999999999 !important;
  border: 1px solid white;
  box-shadow: -5px 6px 12px rgba(209, 73, 37, 0.2);
  font-family: 'FuturaPT-Book', 'Open Sans', sans-serif;
}

.Toastify__toast-body {
  white-space: pre-line;
}

.Toastify__toast--success {
  background-color: #33becc;
}

.Toastify__toast--error {
  background-color: #d14925;
}

.MuiAutocomplete-popper {
  width: unset !important;
}

.DateRangePicker_picker {
  z-index: 999999 !important;
  top: 30px !important;
}

@media (max-width: 768px) {
  .DateRangePicker_picker {
    top: 0 !important;
  }
}
